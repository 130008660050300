@mixin roundedCorners {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@mixin rotateBase {
  -webkit-transform: rotate3d(-1, 1, 0, 0deg);
  -ms-transform: rotate3d(-1, 1, 0, 0deg);
  -o-transform: rotate3d(-1, 1, 0, 0deg);
  transform: rotate3d(-1, 1, 0, 0deg);
}

@mixin rotateRight {
  -webkit-transform: rotate3d(-1, 1, 0, 30deg);
  -ms-transform: rotate3d(-1, 1, 0, 30deg);
  -o-transform: rotate3d(-1, 1, 0, 30deg);
  transform: rotate3d(-1, 1, 0, 30deg);
}

@mixin rotateLeft {
  -webkit-transform: rotate3d(-1, 1, 0, -30deg);
  -ms-transform: rotate3d(-1, 1, 0, -30deg);
  -o-transform: rotate3d(-1, 1, 0, -30deg);
  transform: rotate3d(-1, 1, 0, -30deg);
}

// n is number of stars generated
@function generateStars($n) {
  $value: "#{0} #{random(100)}vw #{random(1500)}vh #FFF";

  @for $i from 2 through $n {
    $value: "#{$value} , #{random(100)}vw #{random(1500)}vh #FFF";
  }

  @return unquote($value);
}
$stars-small: generateStars(500);
$stars-medium: generateStars(200);
$stars-big: generateStars(100);
#stars-containerIntro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #191f24 100%);
  overflow: hidden;
}
#stars-containerBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #010245 0%, #003470 100%);
  overflow: hidden;
}
#starsIntro {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: animateStarsIntro 28.5s ease-in-out infinite alternate;
  @include roundedCorners;

  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
    @include roundedCorners;
  }
}
#starsBg {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $stars-small;
  animation: animateStarsBg 6s ease-in-out infinite alternate;
  @include roundedCorners;
  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
    @include roundedCorners;
  }
}
#stars2Intro {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: animateStarsIntro 30s ease-in-out infinite alternate;
  @include roundedCorners;
  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
    @include roundedCorners;
  }
}
#stars2Bg {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $stars-medium;
  animation: animateStarsBg 6s ease-in-out infinite alternate;
  @include roundedCorners;
  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
    @include roundedCorners;
  }
}
#stars3Intro {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  animation: animateStarsIntro 31.5s ease-in-out infinite alternate;
  @include roundedCorners;
  &:after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    @include roundedCorners;
  }
}
#stars3Bg {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $stars-big;
  animation: animateStarsBg 6s ease-in-out infinite alternate;
  @include roundedCorners;
  &:after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    @include roundedCorners;
  }
}
@keyframes animateStarsIntro {
  0% {
    @include rotateBase;
  }
  25% {
    @include rotateRight;
  }
  50% {
    @include rotateBase;
  }
  75% {
    @include rotateLeft;
  }
  100% {
    @include rotateBase;
  }
}
@keyframes animateStarsBg {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-800px);
  }
}
